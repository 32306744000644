<template>
  <div>
    <div class="super">
      <div class="holder">
        <SmallNav />
        <div class="properties_count">
          <router-link to="/dashboard/rented">
            <img src="../assets/images/thickbackarrow.svg" alt="back" />
          </router-link>
          <h6>
            <router-link to="/dashboard/rented">
              Property List
            </router-link>
          </h6>
        </div>
        <div class="button_switch">
          <button
            @click="activeTab = 1"
            :class="{ active_shift: activeTab === 1 }"
            class="btn"
          >
            Add landlord/PM
          </button>
          <button
            :disabled="!emailcheck"
            @click="activeTab = 2"
            :class="{ active_shift: activeTab === 2 }"
            class="btn"
          >
            Add Rent Receiving Account
          </button>
        </div>
        <div class="landlord_form">
          <!-- <label for="exampleFormControlInput1">Email address</label> -->
          <div class="input-group mb-3 email_checker" v-if="!emailcheck">
            <input
              type="email"
              class="form-control input-bar4"
              placeholder="Landlord or PM email for check"
              aria-label="Recipient's username"
              aria-describedby="button-addon2"
              v-model="email"
            />
            <div class="input-group-append">
              <button
                class="btn check_btn"
                type="button"
                id="button-addon2"
                @click="validateEmail()"
                :disabled="email === ''"
              >
                <!-- checkEmailExist(); -->
                <span v-if="!emailLoader">Check Email</span>
                <i
                  class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                  v-if="emailLoader"
                ></i>
              </button>
            </div>
          </div>
          <span v-show="wrongEmail" style="color:red"
            >Incorrect email address</span
          >
          <form v-if="activeTab === 1 && emailcheck" class="">
            <h4>WE WANT TO KNOW YOUR LANDLORD/PROPERTY MANAGER</h4>
            <div class="form-group">
              <select
                :class="{ errorData: dataa && who === '' }"
                class="form-select llpm form-control input-bar"
                aria-label="Landlord or property manager"
                v-model="who"
              >
                <option value="" selected disabled>Who are you adding?</option>
                <option value="landlord">Landlord</option>
                <option value="property manager">Property Manager</option>
              </select>
              <label style="color: #dc3545" for="who" v-if="dataa && who === ''"
                >Required</label
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                :class="{ errorData: dataa && !firstname.trim() }"
                class="form-control input-bar"
                id="firstname"
                placeholder="| Your Landlord/Manager’s First Name"
                v-model="firstname"
              />
              <label
                style="color: #dc3545"
                for="firstname"
                v-if="dataa && firstname === ''"
                >First name is required</label
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                :class="{ errorData: dataa && !lastname.trim() }"
                class="form-control input-bar"
                id="lastname"
                placeholder="| Your Landlord/Manager’s Last Name"
                v-model="lastname"
              />
              <label
                style="color: #dc3545"
                for="lastname"
                v-if="dataa && lastname === ''"
                >Last name is required</label
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control input-bar"
                id="middlename"
                placeholder="| Any other name"
                v-model="middlename"
              />
            </div>
            <div class="form-group">
              <input
                type="email"
                :class="{ errorData: dataa && !email.trim() }"
                class="form-control input-bar"
                id="email"
                placeholder="| Your Landlord/Manager’s Email"
                v-model="email"
              />
              <label
                style="color: #dc3545"
                for="who"
                v-if="dataa && email === ''"
                >Email is required</label
              >
            </div>
            <div class="form-group">
              <input
                type="text"
                class="form-control input-bar"
                id="phone"
                placeholder="| Phone Number"
                v-model="phone"
              />
            </div>
            <div class="form-group">
              <!-- :class="{ errorData: dataa && !current_location.trim() }" -->
              <input
                type="text"
                class="form-control input-bar"
                id="current_location"
                placeholder="| Address"
                v-model="current_location"
              />
            </div>
            <div class="form-group">
              <select
                class="form-select form-control input-bar"
                aria-label="Country"
                v-model="countryid"
                @change="getStates()"
              >
                <option value="" disabled>Country of Residence</option>
                <option
                  v-for="(country, index) in countries"
                  :selected="countryid"
                  :key="index"
                  :value="country.id"
                  >{{ country.name }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <select
                class="form-select form-control input-bar"
                aria-label="State"
                @change="getCities()"
                v-model="stateid"
              >
                <option value="" selected disabled>State</option>
                <option
                  v-for="(state, index) in states"
                  :key="index"
                  :value="state.id"
                  >{{ state.name }}</option
                >
              </select>
            </div>
            <div class="form-group">
              <select
                class="form-select form-control input-bar"
                aria-label="Landlord or property manager"
                v-model="cityid"
              >
                <option value="" selected disabled>City</option>
                <option
                  v-for="(city, index) in cities"
                  :selected="cityid"
                  :key="index"
                  :value="city.id"
                  >{{ city.name }}</option
                >
              </select>
            </div>
          </form>
          <div v-if="activeTab === 2" class="landlord_account">
            <div class="container">
              <div class="row">
                <div class="col-md-7 account_holder">
                  <div class="form-group">
                    <label for="bank"
                      >Select Bank Name <span class="asterix">*</span></label
                    >
                    <select
                      :class="{ errorData: info && bankname === '' }"
                      class="form-select form-control input-bar3"
                      id="bank"
                      aria-label="Bank"
                      v-model="bankname"
                    >
                      <option value="" selected disabled>--Select--</option>
                      <option
                        :value="bank"
                        v-for="(bank, index) in banks"
                        :key="index"
                        >{{ bank }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <label for="account-number"
                      >Account number <span class="asterix">*</span></label
                    >
                    <input
                      :class="{ errorData: info && accountnumber === '' }"
                      type="number"
                      maxlength="10"
                      class="form-control input-bar2"
                      id="account-number"
                      v-model="accountnumber"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    />
                  </div>
                  <div class="form-group">
                    <label for="account-name"
                      >Account Name <span class="asterix">*</span></label
                    >
                    <input
                      :class="{ errorData: info && accountname === '' }"
                      type="text"
                      class="form-control input-bar2"
                      id="account-name"
                      v-model="accountname"
                    />
                  </div>
                  <div class="form-group mb-md-0">
                    <label for="amount">Amount(&#8358;)</label>
                    <div class="form-control input-bar2" id="amount">
                      <p>
                        <span class="check_main">
                          <span class="check"></span>
                        </span>
                        &#8358;{{
                          Number(this.detailsProps.price).toLocaleString()
                        }}
                      </p>
                      <p>Annual Rent Rate</p>
                    </div>
                  </div>
                </div>
                <div
                  class="col-md-5 rent_receipt"
                  :class="{ errorData: info && reciptimage === '' }"
                >
                  <div class="upload_receipt">
                    <p>
                      Upload last rent payment receipt<span class="asterix"
                        >*</span
                      >
                    </p>
                    <div
                      class="file_holder"
                      @click="$refs.payment.click()"
                      v-if="!recieptUpload"
                    >
                      <img
                        v-if="!fileUrl"
                        src="../assets/images/uploadfile.svg"
                        alt="receipt"
                      />
                      <img
                        v-if="fileUrl"
                        class="exiting_reciept_img"
                        :src="fileUrl"
                        alt="receipt"
                      />
                      <input
                        ref="payment"
                        type="file"
                        name="receipt"
                        id="receipt"
                        class="d-none"
                        @change="uploadPaymentReceipt($event)"
                      />
                      <p v-if="!uplaodingData">Click here to upload an image</p>
                      <h6 class="uploading_text" v-if="uplaodingData">
                        Uploading please wait...
                      </h6>
                    </div>
                    <div v-else class="file-holder">
                      <div
                        class="file_holder file_uploaded_successfully"
                        @click="$refs.payment.click()"
                      >
                        <img
                          src="../assets/images/check-uploaded.svg"
                          alt="file"
                        />
                        <input
                          ref="payment"
                          type="file"
                          name="receipt"
                          id="receipt"
                          class="d-none"
                          @change="uploadPaymentReceipt($event)"
                        />
                        <p>
                          File uploaded successfully
                        </p>
                      </div>
                    </div>
                    <div class="file_format">
                      <h6>File formats PNG, JPEG or PDF</h6>
                      <p v-if="uplaodingData">{{ reciptimage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab === 2" class="form-group form-check">
            <input
              :checked="disclaimer"
              type="checkbox"
              class="form-check-input"
              id="exampleCheck1"
              v-model="disclaimer"
            />
            <label class="form-check-label" for="exampleCheck1"
              >I hereby declare that the information presented here are true and
              correct and that I will be liable for any false information given
              thereof.</label
            >
            <small
              class="disclaimer_text"
              v-if="disclaimer === false && info === true"
              >Check the box if you agree</small
            >
          </div>
          <!-- v-if="(editDetailsLandlord === false) || (editDetailsAccount === false)" -->
          <!-- <div v-if="error" class="required_flelds"><p>Fill required filds</p></div> -->
          <div v-if="emailcheck" class="save_btn">
            <button class="btn btn-block" @click="postData()">
              SAVE
              <i
                class="fa fa-spinner fa-spin  ml-3 f-12  btn-loader"
                v-if="loader"
              ></i>
            </button>
          </div>

          <!-- Rent finance check modal data begin-->
          <div class="modal" tabindex="-1" id="cantaddedit">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title">Information:</h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body notverified_body">
                  <h6>
                    This property has been verified, contact
                    <span>Shelta</span> if you want to update any details.
                  </h6>
                  <p>Property PIN: {{ propPin }}</p>
                  <a href="https://api.whatsapp.com/send?phone=2348061796909"
                    ><img
                      src="../assets/images/whatsApp-blue.svg"
                      alt="whatsapp"
                    /><small>(+234)8061796909</small></a
                  >
                </div>
                <!-- <div class="modal-footer check_btn">
                <button type="button" class="btn" data-dismiss="modal">
                  Cancel
                </button>
                
              </div> -->
              </div>
            </div>
          </div>
          <!-- Rent finance check modal data end -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SmallNav from "./SmallNav";
import { Service } from "../store/service";
const Api = new Service();
import $ from "jquery";

export default {
  name: "TenantAddLandlordPm",
  components: {
    SmallNav
  },
  data() {
    return {
      activeTab: 1,
      loader: false,
      dataa: false,
      error: false,
      info: false,
      emailcheck: false,
      emailLoader: false,
      uplaodingData: false,
      recieptUpload: false,
      who: "",
      countries: [],
      states: [],
      cities: [],
      firstname: "",
      lastname: "",
      middlename: "",
      email: "",
      phone: "",
      propertyid: "",
      current_location: "",
      editDetailsLandlord: false,
      editDetailsAccount: false,
      fileUrl: "",
      countryid: "",
      stateid: "",
      cityid: "",
      detailsProps: "",
      accountname: "",
      accountnumber: "",
      bankname: "",
      reciptimage: "",
      disclaimer: false,
      banks: [],
      propPin: "",
      wrongEmail: false
    };
  },
  mounted() {
    // this.checkEmailExist();
    this.id = JSON.parse(localStorage.getItem("shelta-user")).id;
    // this.getCountries();
    this.detailsProps = Api.getPropertyDetails();
    if (this.detailsProps.id === undefined || this.detailsProps.id === null) {
      this.$router.push({
        path: `/dashboard/rented`
      });
    } else {
      this.getCountries();
      this.getLandlordPMDetails();
      this.getBankNames();
    }
    if (this.detailsProps.haslandlord === "YES") {
      this.emailcheck = true;
      this.editDetailsLandlord = true;
      // this.getCountries();
    } else {
      this.editDetailsLandlord = false;
    }
    if (this.detailsProps.hasaccountdetails === "YES") {
      this.emailcheck = true;
      this.editDetailsAccount = true;
    } else {
      this.editDetailsAccount = false;
    }
  },
  // created() {},
  methods: {
    async getBankNames() {
      try {
        await Api.getRequest(`getbanks`)
          .then(res => {
            this.banks = res.data.banks;
          })
          .catch(err => {
            console.log("Error", err);
          });
      } finally {
        // console.log("closed connect");
      }
    },
    async getLandlordPMDetails() {
      const propId = this.detailsProps.id;
      try {
        await Api.getRequest(`readaddedlandlorddetailsbypropertyid/${propId}`)
          .then(res => {
            if (this.detailsProps.haslandlord == "YES") {
              this.landlordPMDetails = res.data.landlorddetails;
              this.firstname = res.data.landlorddetails.firstname;
              this.lastname = res.data.landlorddetails.lastname;
              this.othernames = res.data.landlorddetails.othernames;
              this.email = res.data.landlorddetails.email;
              this.phone = res.data.landlorddetails.phone;
              this.current_location = res.data.landlorddetails.current_location;
              this.who = res.data.propertyObject.whomanages;
              if (this.countryid === "") {
                // this.getCountries();
                this.countryid = res.data.landlorddetails.countryid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              if (this.stateid === "") {
                this.getStates();
                this.stateid = res.data.landlorddetails.stateid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              if (this.cityid === "") {
                this.getCities();
                this.cityid = res.data.landlorddetails.cityid;
              } else {
                this.countryid = res.data.landlorddetails.countryid;
              }
              this.countryid = res.data.landlorddetails.countryid;
              this.stateid = res.data.landlorddetails.stateid;
              // this.getStates();
              this.cityid = res.data.landlorddetails.cityid;
              // this.firstname = res.data.landlorddetails.firstname;
            }
            if (this.detailsProps.hasaccountdetails == "YES") {
              this.landlordAccountDetails = res.data.otherdetails;
              this.bankname = res.data.otherdetails.bankname;
              this.accountnumber = res.data.otherdetails.accountnumber;
              this.accountname = res.data.otherdetails.accountname;
              this.disclaimer = res.data.otherdetails.disclaimer;
              this.fileUrl = res.data.otherdetails.previouspayment;
            }

            this.landlordPMDetails = res.data.landlorddetails;
          })
          .catch(err => {
            console.log("error", err);
          });
      } finally {
        // console.log("Closed");
      }
    },
    async updateDetailsLandlord() {
      try {
        await Api.postRequest(`tenanteditlandlord`, {})
          .then(res => {})
          .catch(err => {
            console.log("err", err);
          });
      } finally {
        // console.log("closed");
      }
    },
    async checkEmailExist() {
      this.emailLoader = true;
      try {
        await Api.getRequest(`checkuserwithemailexist/${this.email}`)
          .then(res => {
            this.emailLoader = false;
            this.emailcheck = true;
            if (
              res.data.message == "user found" &&
              res.data.success == "success"
            ) {
              const data = res.data.user;
              this.email = data.email;
              this.firstname = data.firstname;
              this.lastname = data.lastname;
              this.countryid = data.countryid;
              this.stateid = data.stateid;
              this.getStates();
              this.cityid = data.cityid;
              this.getCities();
              this.current_location = data.address;
              this.middlename = data.othernames;
              this.phone = data.phone;
              this.bankname = data.bankname;
              this.accountnumber = data.accountnumber;
              this.accountname = data.accountname;
            }
          })
          .catch(err => {
            console.log("Err", err);
          });
      } finally {
        // console.log('closed');
      }
    },
    validateEmail() {
      const emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRe.test(this.email)) {
        this.wrongEmail = false;
        this.checkEmailExist();
      } else {
        this.wrongEmail = true;
      }
    },
    async uploadPaymentReceipt() {
      if (!this.fileUrl) {
        this.uplaodingData = true;
        this.$store.commit("setApiWarning", "Uploading...");
        this.file = this.$refs.payment.files[0];
        this.reciptimage = this.file.name;
        if (!this.file) {
          this.uplaodingData = false;
          return this.$store.commit("setApiFailed", "Nothing was selected!");
        }
        if (
          this.file.type === "image/jpeg" ||
          this.file.type === "image/jpg" ||
          this.file.type === "image/png" ||
          this.file.type === "image/gif"
        ) {
          this.uplaodingData = true;
        } else {
          this.uplaodingData = false;
          return this.$store.commit(
            "setApiFailed",
            "Please select a pdf or image file"
          );
        }
        const formData = new FormData();
        const sizeInMB = (this.file.size / (1024 * 1024)).toFixed(3);
        this.fileSizeInMb = sizeInMB;
        formData.append("file", this.file);
        if (sizeInMB > 5) {
          this.fileSizeType = true;
          this.uplaodingData = false;
          return this.$store.commit(
            "setApiFailed",
            "File size too large, should be more than 5MB"
          );
        } else {
          try {
            await Api.postRequest(`uploadpreviouspayment`, formData)
              .then(res => {
                this.$store.commit("setApiWarning", "");
                this.reciptimage === "";
                this.uplaodingData = false;
                if (res.data.error) {
                  // this.fileSizeType = true;
                  return this.$store.commit("setApiFailed", res.data.error);
                } else if (res.data.success) {
                  this.recieptUpload = true;
                  this.reciptimage = res.data.filename;
                  this.propimgname = res.data.filename;
                  this.$store.commit("setApiSuccess", "success");
                }
              })
              .catch(err => {
                this.uplaodingData = false;
                this.recieptUpload = false;
              });
          } finally {
            // console.log("Closed connection");
          }
        }
      } else {
        // console.log("You cant uploaded");
      }
    },
    postData() {
      if (this.detailsProps.verified == "YES") {
        this.propPin = this.detailsProps.pin;
        $("#cantaddedit")
          .addClass("fade")
          .modal("show");
      } else {
        if (this.activeTab === 1 && this.editDetailsLandlord === false) {
          this.addedLandlord();
        }
        if (this.activeTab === 1 && this.editDetailsLandlord === true) {
          return this.editLandlord();
        }
        if (this.activeTab === 2 && this.editDetailsLandlord === false) {
          return this.addAccountDetails();
        } else {
          this.editAccountDetails();
        }
        // if ((this.activeTab === 1) && (this.editDetailsAccount === true)) {
        //   return this.editLandlord();
        // }
        // if ((this.activeTab === 2) && (this.editDetailsAccount === true)) {
        //   return this.addAccountDetails();
        // } else {
        //   return this.addAccountDetails();
        // }
      }
    },
    async addAccountDetails() {
      this.info = true;
      this.loader = true;
      if (
        this.bankname === "" ||
        this.accountnumber === "" ||
        this.accountname === "" ||
        this.reciptimage === "" ||
        this.disclaimer === false
      ) {
        this.info = true;
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill required fields");
      }
      // if ( this.fileUrl === "") {
      //   this.info = true;
      //   this.loader = false;
      //   return this.$store.commit("setApiFailed", "Select a fields");
      // }
      else {
        try {
          await Api.postRequest(`providerentfinancingaccountdetails`, {
            accountnumber: this.accountnumber,
            accountname: this.accountname,
            bankname: this.bankname,
            previouspayment: this.reciptimage,
            propertyid: this.detailsProps.id
            // tenantmodeofpaymentid: parseInt(
            //   this.detailsProps.tenantmodeofpaymentid
            // ),
          })
            .then(res => {
              this.loader = false;
              if (res.data.success) {
                // this.accountnumber === "";
                // this.accountname === "";
                // this.bankname === "";
                // this.disclaimer === false;
                this.$store.commit("setApiSuccess", res.data.success);
                this.$router.push({
                  path: `/dashboard/rented`
                });
              }
            })
            .catch(err => {
              this.loader = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async editAccountDetails() {
      this.info = true;
      this.loader = true;
      if (
        this.bankname === "" ||
        this.accountnumber === "" ||
        this.accountname === "" ||
        this.disclaimer === false
      ) {
        this.info = true;
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill all required fields");
      }
      // if (this.fileUrl === "") {
      //   if (this.reciptimage === "") {
      //     console.log("File url", this.fileUrl, this.reciptimage);
      //     // this.info = true;
      //     this.loader = false;
      //     return this.$store.commit("setApiFailed", "Select a file");
      //   }
      // }
      else {
        try {
          await Api.postRequest(`providerentfinancingaccountdetailsupdate`, {
            accountnumber: this.accountnumber,
            accountname: this.accountname,
            bankname: this.bankname,
            previouspayment: this.reciptimage,
            propertyid: this.detailsProps.id
            // tenantmodeofpaymentid: parseInt(
            //   this.detailsProps.tenantmodeofpaymentid
            // ),
          })
            .then(res => {
              this.loader = false;
              if (res.data.success) {
                // this.accountnumber === "";
                // this.accountname === "";
                // this.bankname === "";
                // this.disclaimer === false;
                this.$store.commit("setApiSuccess", res.data.success);
                this.$router.push({
                  path: `/dashboard/rented`
                });
              }
              if (res.data.error) {
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              this.loader = false;
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async editLandlord() {
      this.loader = true;
      this.dataa = true;
      if (
        this.email === "" ||
        this.firstname === "" ||
        // this.current_location === "" ||
        this.lastname === "" ||
        this.who === ""
        // this.phone === ""
      ) {
        this.dataa = true;
        this.loader = false;
        return this.$store.commit("setApiFailed", "Fill required fields");
      } else {
        try {
          // console.log("Check");
          await Api.postRequest(`tenanteditlandlord`, {
            whomanages: this.who,
            firstname: this.firstname,
            lastname: this.lastname,
            middlename: this.middlename,
            email: this.email,
            phone: this.phone,
            propertyid: this.detailsProps.id,
            current_location: this.current_location,
            countryid: this.countryid,
            stateid: this.stateid,
            cityid: this.cityid
          })
            .then(res => {
              this.loader = false;
              if (res.data.success) {
                this.email === "";
                this.firstname === "";
                this.current_location === "";
                this.lastname === "";
                this.who === "";
                this.phone === "";
                this.countryid === "";
                this.stateid === "";
                this.cityid === "";
                return this.$store.commit("setApiSuccess", res.data.success);
              } else {
                this.loader = false;
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log(err);
              this.loader = false;
              return this.$store.commit("setApiFailed", err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async addedLandlord() {
      this.loader = true;
      this.dataa = true;
      if (
        this.email === "" ||
        this.firstname === "" ||
        // this.current_location === "" ||
        this.lastname === "" ||
        this.who === ""
        // this.phone === ""
      ) {
        this.dataa = true;
        this.loader = false;
        // this.error = true;
        return this.$store.commit("setApiFailed", "Fill required fields");
      } else {
        try {
          await Api.postRequest(`tenantaddlandlord`, {
            whomanages: this.who,
            firstname: this.firstname,
            lastname: this.lastname,
            middlename: this.middlename,
            email: this.email,
            phone: this.phone,
            propertyid: this.detailsProps.id,
            current_location: this.current_location,
            countryid: this.countryid,
            stateid: this.stateid,
            cityid: this.cityid
          })
            .then(res => {
              this.loader = false;
              if (res.data.success) {
                this.email === "";
                this.firstname === "";
                this.current_location === "";
                this.lastname === "";
                this.who === "";
                this.phone === "";
                this.countryid === "";
                this.stateid === "";
                this.cityid === "";
                this.loader = false;
                if (this.detailsProps.hasaccountdetails == "NO") {
                  this.activeTab = 2;
                }
                return this.$store.commit("setApiSuccess", res.data.success);
              } else {
                this.loader = false;
                return this.$store.commit("setApiFailed", res.data.error);
              }
            })
            .catch(err => {
              console.log(err);
              this.loader = false;
              this.loader = false;
              return this.$store.commit("setApiFailed", err);
            });
        } finally {
          // console.log("Closed connection");
        }
      }
    },
    async getCountries() {
      this.$store.commit("setApiWarning", "Loading countries...");
      try {
        await Api.getRequest(`fetchallcountries`)
          .then(res => {
            this.$store.commit("setApiWarning", "Loading");
            if (res.data.success) {
              this.countries = res.data.countries;
              return this.$store.commit("setApiSuccess", "countries loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getStates: async function() {
      const id = this.countryid;
      this.$store.commit("setApiWarning", "Loading state...");
      try {
        await Api.getRequest(`fetchstatesbycountryid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.states = res.data.states;
              return this.$store.commit("setApiSuccess", "state loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    },
    getCities: async function() {
      const id = this.stateid;
      this.$store.commit("setApiWarning", "Loading cities");
      try {
        await Api.getRequest(`fetchcitiesbystateid/${id}`)
          .then(res => {
            this.$store.commit("setApiWarning", "");
            if (res.data.success) {
              this.cities = res.data.cities;
              return this.$store.commit("setApiSuccess", "cities loaded");
            } else if (res.data.error) {
              return this.$store.commit("setApiFailed", res.data.error);
            }
          })
          .catch(err => {
            // this.loader2 = false;
            // console.log(err);
          });
      } finally {
        // console.log("Closed connection");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$secondary: #ffffff;
$tatiary: #a2b0e3;
$black: #000000;
$black2: #222222;
$yellow: #ffb100;

@font-face {
  font-family: "Gotham-bold";
  src: local("Gotham-bold"),
    url("../assets/fonts/shelta_fonts/Gotham\ Bold.otf") format("truetype");
}
@font-face {
  font-family: "Gotham";
  src: local("Gotham"),
    url("../assets/fonts/shelta_fonts/Gotham\ Book.otf") format("truetype");
}
@font-face {
  font-family: "Gotham-medium";
  src: local("Gotham-medium"),
    url("../assets/fonts/shelta_fonts/Gotham\ Medium.otf") format("truetype");
}

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  position: relative;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
  background-color: #efefee;
}

a {
  color: inherit !important;
  text-decoration: none;
}
.properties_count {
  text-align: left;
  padding: 30px 20px;
  img {
    cursor: pointer;
  }
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: $black;
  }
}

.button_switch {
  button {
    background: #ffffff;
    max-width: 207px;
    width: 100%;
    font-family: Gotham;
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 40px;
    letter-spacing: -0.05em;
    text-transform: capitalize;
    color: $black;
    border-radius: 0;
    box-shadow: none;
    outline: 0;
    border: 0.5px solid #3a55b5;
    // border: 0.5px solid #0033EA;
  }
}

.active_shift {
  background: $primary !important;
  color: $secondary !important;
}

.form-group {
  margin-bottom: 1.9rem;
  label {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: $black;
    position: relative;
  }
  .form-check-label {
    display: inline-block;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 153%;
    text-align: left;
    position: relative;
  }
}
.asterix {
  margin: 0px 0 0 5px;
  font-size: 20px;
  position: relative;
  // left: 126px;
  top: 4px;
  color: #dc3545;
}
.form-check {
  margin: 30px 66px;
}
input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-left: -2.25rem;
}

.landlord_form {
  background: $secondary;
  border-radius: 10px;
  max-width: 992px;
  width: 100%;
  margin: auto;
  padding: 40px 0 20px;
  form {
    border: 0.2px solid #cecece;
    margin: 78px 212px;
    padding: 14px 50px;
    h4 {
      font-family: Gotham;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 40px;
    }
  }
}

.llpm {
  // background: $primary !important;
  // color: $secondary !important;
}

.disclaimer_text {
  font-family: Gotham;
  font-style: normal;
  font-size: 12px;
  color: #dc3545;
}
.errorData {
  border: 0.5px solid #dc3545 !important;
}
.successData {
  border: 0.5px solid #8596d3 !important;
}

.input-bar {
  border-radius: 0;
  background: $secondary;
  height: 44px;
  border: 0.5px solid #8596d3;
  // border: 0.2px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  height: 50px;
  align-content: normal;
  font-family: Gotham;
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  // text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
  option {
    background: $secondary;
    color: $black;
  }
}

.email_checker {
  max-width: 500px;
  width: 100%;
  margin: auto;
  padding: 0 15px;
}
.input-bar4 {
  border-radius: 0;
  background: $secondary;
  height: 44px;
  border: 0.5px solid #8596d3;
  // border: 0.2px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;
  height: 50px;
  align-content: normal;
  font-family: Gotham;
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  // text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
}
.check_btn {
  background: $yellow;
  color: $black;
  font-family: Gotham;
  font-style: normal;
  font-weight: 500;
  height: 50px;
  border: 1px solid $yellow;
  font-size: 13px;
  box-shadow: none;
  outline: 0;
}

.required_flelds {
  p {
    color: #dc3545;
    font-family: Gotham;
    font-style: normal;
    font-size: 20px;
  }
}
.save_btn {
  margin: 0 32px;
  button {
    background: $primary;
    color: $secondary;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    text-align: center;
    height: 43px;
  }
}

.check_btn {
  margin: auto;
  button:first-child {
    background: $yellow;
    color: $black;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    outline: 0;
    box-shadow: none;
  }
  button:hover {
    animation: pulse 1s infinite;
    transition: 0.3s;
  }
  button:nth-child(2) {
    background: $primary;
    color: $secondary;
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
  }
}

.instruction_body {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    text-align: left;
    margin-bottom: 20px;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    font-size: 15px;
    .notsupported {
      max-width: 5%;
      width: 100%;
    }
  }
}
.notverified_body {
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    text-align: left;
    margin-bottom: 10px;
    span {
      color: $primary;
    }
  }
  p {
    font-family: Gotham;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    font-size: 16px;
  }
  small {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    text-align: left;
    font-size: 20px;
    color: #2fc643;
  }
  a {
    text-decoration-color: #2fc643;
    img {
      width: 6%;
      margin-right: 10px;
    }
  }
}

.landlord_account {
  padding: 30px 50px;
}
.input-bar2 {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: none;
  outline: 0;
  height: 45px;
  text-transform: capitalize;
}
.input-bar3 {
  font-family: Gotham;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 11px;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  box-shadow: none;
  outline: 0;
  color: #6c6c6c;
  height: 45px;
}

#amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #eeeeee;
  border: 1px solid #e5e5e5;
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    align-items: center;
    color: #2fc643;
    margin-bottom: 0;
  }
  p:nth-child(2) {
    font-family: Gotham;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #6c6c6c;
    margin-bottom: 0;
  }
}
.check_main {
  border-radius: 50%;
  background: #2fc643;
  padding: 3px 10px;
  top: 0px;
  position: relative;
  .check {
    display: inline-block;
    transform: rotate(45deg);
    height: 12px;
    width: 6px;
    border-bottom: 3px solid #ffffff;
    border-right: 3px solid #ffffff;
  }
}
.upload_receipt {
  margin: 20px 33px;
  p:first-child {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    align-items: center;
    text-align: left;
    color: $black;
  }
}
.file_holder {
  padding: 36px 20px;
  background: $secondary;
  box-shadow: 10px 10px 30px rgb(0 0 0 / 10%);
  cursor: pointer;
  p:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.9);
  }
  h6:nth-child(3) {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    align-items: center;
    text-align: center;
    margin-top: 25px;
    color: #ff9c2a;
  }

  .file_uploaded_successfully {
    padding-top: 3.5rem !important;
    p:nth-child(3) {
      color: #28a745 !important;
    }
  }
  // .upload-file-holder {
  //   margin: 3rem auto;
  //   margin-bottom: 1.5rem;
  //   width: 285px;
  //   height: 202px;
  //   background: #ffffff;
  //   box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.1);
  // }

  .uploading_text {
    animation: blinker 1s linear infinite;
  }

  .exiting_reciept_img {
    width: 100%;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
}
.rent_receipt {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  margin-top: 25px;
}

.file_format {
  padding-top: 20px;
  h6 {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    display: flex;
    align-items: center;
    color: #b93623;
  }
  p {
    font-family: Gotham;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    // display: flex;
    align-items: center;
    color: #2fc643;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 599px) {
  .button_switch {
    display: flex;
    margin: 0 15px;
    button {
      line-height: 20px;
    }
  }
  .landlord_form {
    border-radius: 5px;
    form {
      margin: 15px 15px;
      padding: 15px 15px;
      h4 {
        line-height: 30px;
        margin-bottom: 25px;
      }
    }
  }
  .landlord_account {
    padding: 30px 15px;
  }
  .upload_receipt {
    margin: 20px 5px;
  }
  #amount {
    p {
      font-size: 15px;
      line-height: 32px;
    }
  }
  .form-check {
    margin: 30px 32px;
  }
  // .upload-file-holder {
  //   width: 240px;
  // }
}

@media screen and (max-width: 768px) {
  .landlord_form {
    form {
      margin: 78px 20px;
    }
  }
}
</style>
